<template>
    <div id="container">
        <div id="contents">
            <div class="contents_body">
                <div class="ncs-wrap">
                    <div class="my-ncs">
                        <div class="ncs-tit">
                            NCS 직무 자가진단 평가는 등록된 기술신고서의 기술등급(학력기준과 자격증기준)을 기준으로 진행됩니다.
                        </div>
                    </div>
                    
                    <div class="my-ncs">
                        <div class="ncs-info">
                            <span v-if="techData.mberNm != undefined">{{techData.mberNm}}</span>님의 기술신고서 기술등급(학력기준, 자격증기준)은 다음과 같습니다.
                            <div class="ncs-box">
                                기술등급(학력기준) : <span class="bluetxt" v-if="techData.resumeSchoolTechGradeNm != undefined">{{techData.resumeSchoolTechGradeNm}}</span> / 
                                기술등급(자격증기준) : <span class="redtxt" v-if="techData.resumeLicenseTechGradeNm != undefined">{{techData.resumeLicenseTechGradeNm}}</span>
                            </div>
                            
                            <div class="ncs-meg">	
                                <span class="ico_info"></span> 기술등급(학력기준), 기술등급(자격증기준) 중에서 높은 등급을 기준으로 NCS 직무 평가가 진행됩니다.<br>
                                <span class="ico_none"></span> 예 : 기술등급(학력기준) : 초급 / 기술등급(자격증기준) : 고급 = 기술등급(자격증기준) 고급 기준으로 자가진단이 진행)
                            </div>
                        </div>
                    </div>
                    
                    <div class="my-ncs">
                        <div class="my-ncs-tit">NCS 직무 자가진단 평가 <span class="ps">(※ NCS 직무 자가진단 평가를 여러개 진행할 경우 직무분야를 선택 후 추가 버튼을 클릭해 주세요.)</span></div>
                        <div class="ncs-section">
                            <div class="ncs-clas">
                                <div class="clas-tit">대분류</div>
                                <div class="clas-select">정보통신</div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">중분류</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select id="midCd" v-model="data.midCd"> 
                                            <option value="">선택하세요.</option>
                                            <option v-for="option in midClassList" :key="option.midClassCd" v-bind:value="option.midClassCd">{{option.midClassNm}}</option>
                                        </select>

                                        <!-- <select id="midCd" ng-model="data.midCd" > 
                                            <option value="">선택하세요.</option> 
                                            <option ng-repeat="option in data.midClassList" ng-value="option.midClassCd">{{option.midClassNm}}</option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">소분류</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select id="minorCd" v-model="data.minorCd"> 
                                            <option value="">선택하세요.</option> 
                                            <option v-for="option in minorClassList" :key="option.minorClassCd" v-bind:value="option.minorClassCd" v-show="option.midClassCd == data.midCd">{{option.minorClassNm}}</option>
                                        </select>

                                        <!-- <select id="minorCd" ng-model="data.minorCd"> 
                                            <option value="">선택하세요.</option> 
                                            <option ng-repeat="option in data.minorClassList" ng-value="option.minorClassCd" ng-if="option.midClassCd == data.midCd">{{option.minorClassNm}}</option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                            <div class="ncs-clas">
                                <div class="clas-tit-job">직무분야</div>
                                <div class="clas-select-job">
                                    <div class="select_wrap">
                                        <select id="detailCd" v-model="data.detailCd"> 
                                            <option value="">선택하세요.</option>
                                            <option v-for="option in detailClassList" :key="option.detailClassCd" v-bind:value="option.detailClassCd" v-show="option.minorClassCd == data.minorCd">{{option.detailClassNm}}</option>
                                        </select>

                                        <!-- <select id="detailCd" ng-model="data.detailCd"> 
                                            <option value="">선택하세요.</option> 
                                            <option ng-repeat="option in data.detailClassList" ng-value="option.detailClassCd" ng-if="option.minorClassCd == data.minorCd && data.techData.techGrade >= option.ncsGradeDiv">
                                                {{option.detailClassNm}}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>	
                        </div>
                        
                        <div class="ncs-section-add">
                            <div class="btn gray" @click="btnAdd">자가진단 추가</div>
                            <!-- <div class="btn gray" ng-click="btnAdd()">자가진단 추가</div> -->
                        </div>
                        
                        <div class="ncs-info">
                            <div class="ncs-meg">	
                                <span class="ico_info"></span> NCS 직무 자가진단 평가는 <strong>최대 3가지의 직무분야만 가능</strong>합니다.<br>
                                <span class="ico_info"></span> NCS Level 항목에 표시된 Level은 본인이 등록한 기술신고서에 의해 자동으로 산출된 Level이므로,각 Level에 따른 능력단위명에 해당하는 자가진단을 하여야 합니다.<br>
                                <span class="ico_none"></span> 모든 능력단위명을 진단완료하여야 최종적으로 본인의 기술등급이 등록됩니다.<br>
                                <span class="ico_info"></span> NCS 직무 자가평가 등록은 하단의 최종등록을 하기 전까지는 능력단위별 진단지 수정을 할 수 있습니다.<br>
                                <span class="ico_info"></span> NCS Level : 초급(L2~L4), 중급(L5~L6), 고급(L7), 특급(L7~L8)
                            </div>
                        </div>
                    </div>
                    
                    <div class="my-ncs mb0" v-for="(table, index) in unitData" :key="table.ncsDetailClassCd" :id="table.ncsDetailClassCd">
                        <div class="my-ncs-tit-list">
                            <span>{{table.midClassNm}} > {{table.minorClassNm}} > {{table.detailClassNm}}</span> 자가진단 평가 등록
                            <div style="float:right;">
                                <!-- <div class="ncs-filedown" v-if="table.dutySpecYn == 'Y'" @click="btnFileDown(table)">직무기술서 다운로드</div> -->
                                <img src="/images/btn_hide_ncs.png" alt="" @click="btnHideShow(index, false)" v-if="table.show == true">
                                <img src="/images/btn_show_ncs.png" alt="" @click="btnHideShow(index, true)" v-else>
                                <img src="/images/btn_del_ncs.png" alt="" @click="btnDel(table)" v-if="techData.resumeGradeAdmApprYn == 'N'">
                            </div>
                        </div>
                        <div class="my-ncs-table" v-show="table.show">
                            <table class="ncs-type">
                                <colgroup>
                                        <col width="150">
                                        <col width="*">
                                        <col width="100">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>NCS Level</th>
                                            <th>능력단위명</th>
                                            <th>등록여부</th>
                                        </tr>
                                        <tr v-for="row in table.unitList" :key="row.abilUnitCd" v-show="table.unitList.length != 0">
                                            <td class="lev" v-if="row.mergeChkCnt == row.mergeCnt" :rowspan="row.mergeCnt">{{row.ncsLv}}</td>
                                            <td v-on:click="unitClick(row, table)">{{row.abilUnitNm}}</td>
                                            <td v-bind:class="row.chkProg == '등록' ? 'regist-y' : 'regist'" v-on:click="unitClick(row, table)">{{row.chkProg}}</td>
                                        </tr>
                                        <tr v-show="table.unitList.length == 0">
                                            <td colspan="3">{{table.detailClassNm}} 직무는 <span v-for="cd in code_PRO119" :key="cd.code" v-show="techData.techGrade == cd.code">{{cd.codeNm}}</span> 자가진단항목이 없습니다.</td>
                                        </tr>
                                    </tbody>	
                            </table>
                            
                            <div class="my-ncs-btn">
                                <div class="save" @click="btnSubmit(table)" v-if="!table.ncsGradeCd && table.unitList.length != 0">자가진단 평가 등록</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div><!--//contents_body-->
        </div><!--//contents-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            
            data : {
                midCd : "",
                minorCd : "",
                detailCd : ""
            },
            midClassList : [],
            minorClassList : [],
            detailClassList : [],
            techData : {},
            unitData : [],
            code_PRO119 : [],

            options : {
                headers : {'Authorization' : 'Bearer ' + sessionStorage.getItem('access_token')}
            }
        }
    },
    mounted() {
        this.code_PRO119 = this.$getCommonCode('PRO119');
        this.openNcsSelfDiag();
    },

    methods : {
        openNcsSelfDiag() {

            var param = {
                loginSeq : sessionStorage.getItem('seq')
            };
            
            this.$axios.post('/api/res/openNcsSelfDiag', param, this.options)
                .then(res => {
                    // console.log(res.data);
                    this.midClassList = res.data.data.midClassList;
                    this.minorClassList = res.data.data.minorClassList;
                    this.detailClassList = res.data.data.detailClassList;
                    this.techData = res.data.data.techData;
                    this.unitData = res.data.data.unitData;
                })
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 
                    //alert(err);
                    alert(err.response.data.error_description);
                });
        },

        btnAdd () {
            if(this.data.detailCd) {
                for(var i in this.unitData) {
                    if(this.unitData[i].ncsDetailClassCd == this.data.detailCd) {
                        alert("선택하신 직무분야는 이미 추가 되어 있습니다.");
                        return false;
                    }
                }
                
                // 직무 수 체크
                if(this.unitData)
                    if(Object.keys(this.unitData).length == 3) {
                        alert('NCS 직무 자가진단 평가는 최대 3가지의 직무분야만 가능합니다.');
                        return false;
                    }
                
                var param             = new Object();
                param.detailCd        = this.data.detailCd;
                param.techGradeVerSeq = this.techData.techGradeVerSeq;
                param.techGrade       = this.techData.techGrade;
                param.registerFlag    = 'create';
                param.loginSeq        = sessionStorage.getItem('seq');
                
                // console.log('request param :', param);
                
                this.$axios.post('/api/res/addNcsTech', param, this.options)
                    .then(res => {
                        // console.log(res.data);
                        var rs = res.data;

                        if(rs.resultCode == "000"){
                             this.unitData = rs.data.unitData;
                             var idx = Object.keys(this.unitData).length - 1;
                            
                             for(var i in this.unitData) {
                                 if(i < idx) {
                                     this.unitData[i].show = false;
                                 }else {
                                     this.unitData[i].show = true;
                                 }
                             }

                            //  console.log('DATA', this.unitData);
                            //  console.log('RS', rs.data.unitData);
                        }
                    })
                    .catch(err => {
                        // console.log('============================='); 
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('============================='); 
                        //alert(err);
                        alert(err.response.data.error_description);
                    });
            }
        },

        btnDel(row) {
            if(confirm('직무분야 ' + row.detailClassNm + '를 삭제하시겠습니까?')) {
                var param = {};
                param.mberTechGradeFinalSeq = row.mberTechGradeFinalSeq;
                param.registerFlag = 'update';
                param.loginSeq = sessionStorage.getItem('seq');
                
                this.$axios.post('/api/res/addNcsTech', param, this.options)
                    .then(res => {
                        // console.log(res.data);
                        
                        if(res.data.resultCode == "000"){
                            this.unitData = res.data.data.unitData;
                        }
                    })
                    .catch(err => {
                        // console.log('============================='); 
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('============================='); 
                        //alert(err);
                        alert(err.response.data.error_description);
                    });
            }
        },

        btnHideShow(idx, flag) {
            this.$set(this.unitData[idx], "show", flag);
        },

        btnFileDown(table) {
            var param = {};
            param.loginSeq = sessionStorage.getItem('seq');
            param.detailCd = table.ncsDetailClassCd;
            param.detailNm = table.detailClassNm;

            // console.log(param);

            this.$axios.post('/api/res/ncsFileDownLoad', param, this.options)
                .catch(err => {
                    // console.log('============================='); 
                    // console.log(err);
                    // console.log(err.response);
                    // console.log('============================='); 
                    alert(err);
                    //alert(err.response.data.error_description);
                });
            //location.href = '/api/res/ncsFileDownLoad?detailCd='+table.ncsDetailClassCd+'&detailNm='+table.detailClassNm;
        },

        btnSubmit(table) {
            var alertMsg = '';
            
            // console.log('TABLE :', table);
            
            for(var i in table.unitList) {
                if(table.unitList[i].chkProg == '미등록') {
                    alertMsg += table.unitList[i].ncsLv + '.' + table.unitList[i].abilUnitNm + '\n';
                }
            }
            
            if(alertMsg.length == 0) {
                var param = new Object();
                
                param.ncsDetailClassCd = table.ncsDetailClassCd;
                param.ncsLevelCd = table.unitList[table.unitList.length - 1].ncsLv;
                param.mberTechGradeFinalSeq = table.mberTechGradeFinalSeq;
                param.detailClassNm = table.detailClassNm;
                
                // console.log('submit request : ', param);
                
                this.$axios.post('/api/res/ncsSelfDiagEnd', param, this.options)
                    .then(res => {
                        // console.log(res.data);

                        var rs = res.data;
                        
                        if(rs.resultCode == '000'){
                            // console.log(rs);
                            
                            alert('자가진단 평가를 등록하였습니다.');
                            
                            var chkCnt = 0;
                            
                            for(var i in this.unitData) {
                                if(!this.unitData[i].ncsGradeCd && Object.keys(this.unitData[i].unitList).length != 0) {
                                    chkCnt++;
                                }
                            }
                            
                            if(chkCnt <= 1) {
                                this.$move("/res/RES004M01.do");
                            } else {
                                var sendParam = {};
                                sendParam.moveAction = 'back';
                                sendParam.ncsDetailClassCd = table.ncsDetailClassCd;
                                
                                // pnAjax($scope, {
                                // 	data : sendParam,
                                // 	success : function(rs) {
                                // 		console.log('result', rs);
                                        
                                // 		if(rs.resultCode == "000"){
                                // 			$scope.data.unitData = rs.data.unitData;
                                            
                                // 			if($scope.data.moveAction) {
                                // 				for(var i in $scope.data.unitData) {
                                // 					if($scope.data.unitData[i].ncsDetailClassCd == $scope.data.ncsDetailClassCd) {
                                // 						$scope.data.unitData[i].show = true;
                                // 					}else {
                                // 						$scope.data.unitData[i].show = false;
                                // 					}
                                // 				}
                                // 			}
                                // 		}
                                // 	}
                                // });

                                // this.$axios.post('/api/res/addNcsTech', param, options)
                                //     .then(res => {
                                //         console.log(res.data);
                                        
                                //         if(res.data.resultCode == "000"){
                                //             this.unitData = res.data.data.unitData;
                                //         }
                                //     })
                                //     .catch(err => {
                                //         console.log('============================='); 
                                //         console.log(err);
                                //         console.log(err.response);
                                //         console.log('============================='); 
                                //         //alert(err);
                                //         alert(err.response.data.error_description);
                                //     });
                            }
                        }
                    })
                    .catch(err => {
                        // console.log('============================='); 
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('============================='); 
                        //alert(err);
                        alert(err.response.data.error_description);
                    });
            }else {
                alert('자가진단을 완료하지 않았습니다.\n\n' + alertMsg);
                return false;
            }
        },

        unitClick(row, table) {
            // console.log(row);

            this.$router.push('/res/RES003M02?unitCd=' + row.abilUnitCd + '&mberTechGradeFinalSeq=' + table.mberTechGradeFinalSeq);
        }
    }
}
</script>